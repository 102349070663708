import React, { useState, useEffect } from "react"
import TikangaMaori from "./tikangaMaori.js"

const CustomContent = (props) => {
  const [customContent, setCustomContent] = useState()
  const key = props.subject != undefined ? props.subject.customContentKey : ""

  useEffect(() => {
    if (key === "Tikanga Maori") {
      setCustomContent(<TikangaMaori modalState={props.modalState} />)
    } else {
      console.log(`${key} - Custom content key does not match subjects.json`)
    }
  }, [])
  return <div>{customContent}</div>
}

export default CustomContent
