import React from "react"
import { Link } from "gatsby"

// styles -> _subjectPage.scss

import Button from "../../../components/button"
import Dow from "../../../images/DOW.png"

const TikangaMaori = (props) => {
  return (
    <div>
      <p class="subject-description-custom">
        The Dynamics of Whanaungatanga (DoW) is one of New Zealand's leading
        courses educating participants on tikanga Māori and provides a framework
        for business success that can be applied to any organisation globally.
      </p>
      <Link to="/contact">
      <button
        className="dow-btn"
        id="open-modal-button"
       
      >
        Contact us now to reserve a place in our next course
      </button>
      </Link>
      <p class="subject-description-custom" style={{ paddingTop: "30px" }}>
        <span className="dow-bold">Whānaungatanga</span> – a close connection
        between people; kinship.
      </p>
      <p class="subject-description-custom">
        The <span className="dow-bold">Dynamics of Whānaungatanga (DoW)</span>
         provides a practical understanding of concepts and values designed to
        enhance the Tapu (wellbeing), of individuals and organisations. This in
        turn provides individuals and organisations the Mana they need to be
        successful.
      </p>
      <p className="subject-description-custom">
        DoW's training addresses all of the factors impacting on Tapu and Mana,
        including decision making, the roles of people, the impact of breaches
        of trust and the impact on Tapu and Mana, how to resolve disputes and
        ultimately, how to achieve the goals of individuals and organisations.
      </p>
      <img
        src={Dow}
        className="dow-img"
        alt="Personalised Learner Experiences"
        style={{ paddingBottom: "30px" }}
      />

      <div className="dow-heading">DoW Level 1</div>
      <p className="subject-description-custom">
        Level 1 provides participants with all the fundamental knowledge that
        they need to understand and implement DoW within their personal and
        organisational framework.
      </p>
      <p className="dow-description">The course consists of</p>
      <ul>
        <li className="dow-description">
          DoW Theory - Eight topics either done as a two day intensive face to
          face seminar or as eight 90-minute webinar sessions over two weeks.
        </li>
        <li className="dow-description">
          DoW In Action – A practical review 4-6 weeks after the completion of
          DoW Theory to work through the application of DoW in real life
          scenarios. Provided either as a one day intensive or as four 90-minute
          webinar sessions
        </li>
        <li className="dow-description">
          DoW online – 12 months access to all the DoW materials, webinars, and
          a series of short courses designed to refresh and embed the learning
          gained from DoW Theory and DoW in Action
        </li>
      </ul>
      <p
        className="subject-description-custom dow-bold"
        style={{ paddingBottom: "30px" }}
      >
        Cost $1,495 per person plus GST
      </p>

      <div className="dow-heading">DoW Level 2</div>
      <p className="subject-description-custom">
        Level 2 extends the skill sets of participants by providing them with a
        better understanding of how to apply DoW in a much wider set of
        circumstances. It empowers people to lead teams and organisations using
        the DoW principles. This is ideal for executive management teams, or
        team leaders where their direct reports have also undertaken DoW Level
        1.
      </p>
      <p className="dow-description">The course consists of</p>
      <ul>
        <li className="dow-description">
          Level 2 Dow In Action - Either a two day intensive or as eight 90
          minute webinar sessions.
        </li>
        <li className="dow-description">
          DoW online – 12 months access to all the DoW materials, webinars, and
          case studies, including the specific examples dealt with in the DoW In
          Action which are added after each session. Together these form a
          library of reference materials from which to refine you application of
          DoW.
        </li>
      </ul>
      <p
        className="subject-description-custom dow-bold"
        style={{ paddingBottom: "30px" }}
      >
        Cost $1,895 per person
      </p>

      <h6 style={{ paddingBottom: "10px" }}>Additional Options (POA)</h6>

      <p className="dow-description">Corporate Teams Bundles</p>
      <ul>
        <li className="dow-description">
          Group training for companies and pricing can be provided on a cohort
          basis on application.
        </li>
      </ul>

      <p className="dow-description" style={{ paddingTop: "10px" }}>
        Noho Marae
      </p>
      <ul style={{ paddingBottom: "30px" }}>
        <li className="dow-description">
          Options exist to include marae experiences with the courses. This is
          generally only available for corporate bookings. Noho Marae can range
          from day visits to overnight experiences incorporating the DoW in
          Action sessions.
        </li>
      </ul>

      <div className="dow-heading">About DoW</div>
      <p className="subject-description-custom">
        DoW was developed by Pa Henare Tate (PhD) in 1992 and has been
        successfully delivered to a range of government ministries,
        organisations, and businesses ever since.
      </p>
      <div className="dow-heading">The DOW Team</div>
      <p className="subject-description-custom">
        Kāwei has a team of Māori facilitators who are able to extend cultural
        connection and knowledge. Our lead facilitators are:
      </p>
      <p className="dow-description">
        <span className="dow-bold">Albert Robertson</span> (Te Rarawa)
      </p>
      <p className="subject-description-custom">
        Albert is the Managing Director of Kāwei and worked closely alongside Pā
        Tate to develop the DoW programme. He has been delivering the DoW
        programme since 2003 while also leading business development and
        operations. Albert is a master of DoW facilitation and practice, with
        the ability to design and develop bespoke, customised DoW programmes
        specifically for the client's requirements including iwi and
        organisational development, non-violence, drug and alcohol, and
        well-being programmes for tane, wahine and rangatahi as well leading the
        delivery of DoW-based social service delivery for whanau engaged with
        Oranga Tamariki, Ministry of Justice and Department of Corrections.
      </p>
      <p className="dow-description">
        <span className="dow-bold">Charles Royal</span> (Ngati Raukawa)
      </p>
      <p className="subject-description-custom">
        Charles is a senior DoW practitioner and facilitator for Kāwei, having
        delivered many hundreds of hours of DoW programmes throughout Aotearoa
        NZ. For over 20 years Charles has studied, practiced and lived the DoW
        principles to develop a deep understanding for the application of DoW
        and Te Ao Māori in any situation. Kāwei utilises Charles expertise to
        provide specialised one-on-one programmes that require individual
        mentoring or DoW counselling.
      </p>
      <p className="dow-description">
        <span className="dow-bold">Julian Paku</span> (Ngati Kahungungu Ngati
        Tuwharetoa)
      </p>
      <p className="subject-description-custom">
        Julian is helping lead the development and delivery of DoW programmes
        across a range of applications, from rangatahi to organisational
        transformation. He is a Learning & Development practitioner and has many
        years of experience at L&D management and facilitation. He has developed
        and delivered leadership and training programmes for a number of
        corporates including Genesis Energy, Dunn & Bradstreet, Vodafone NZ and
        Vocus Group. He holds NZQA Level 5 qualifications for Adult Education
        and is a qualified NZQA Assessor. He has achieved Level 2 Te Reo Māori.
      </p>
      <p className="subject-description-custom">
        The facilitation team is supported by an Operations Manager and
        Executive Assistant.
      </p>
      <p className="dow-description">
        <span className="dow-bold">Dr Siobhan Rangi-aho Todd</span> (Ngati
        Tuwharetoa and Te Arawa)
      </p>
      <p className="subject-description-custom">
        Siobhan is an Executive Administrator and Operations Manager for DoW
        Whanau Services and critical to the successful and efficient delivery of
        DoW contracts and programmes. Siobahn is qualified with a Masters of
        Chiropractic, and a Bachelor of Human Sciences (Hons) from the
        Anglo-European College of Chiropractic. She also holds a Bachelor of
        Science (Hons) in Business Computer Systems & Business Information
        Technology from the University of Gloucestershire.
      </p>
      <h6>Qualifying for Rule 17 of the Government Procurement Rules</h6>
      <p className="subject-description-custom">
        Both DoW and Summit Institute are Māori organisations and as such, this
        programme qualifies under Rule{" "}
        <a href="https://www.procurement.govt.nz/broader-outcomes/increasing-access-for-new-zealand-businesses/">
          17 of the Government Procurement Rules
        </a>{" "}
        for awarding of contracts to Māori businesses.
      </p>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px',marginTop:'20px'}}>Enrol</a>
      <Link to="/contact">
        <Button style={{ marginTop: "35px" }}>Contact us</Button>
      </Link>
    </div>
  )
}

export default TikangaMaori
